import React from 'react';
import '../pages/learn/BigArticleCard.css';

function BigArticleCard({ image, title, description, author, redirectUrl }) {
  const handleClick = () => {
    window.location.href = redirectUrl;
  };

  return (
    <div className="big-article-card">
      <div className="big-article-image-container">
        <img src={image} alt={title} className="big-article-image" onClick={handleClick} />
      </div>
      <div className="big-article-content">
        <div>
          <h4>{title}</h4>
          <p className="article-description">{description}</p>
          <p className="article-author">{author}</p>
        </div>
        <br />
        <button className="read-more-button" onClick={handleClick}>
          <span>Read More</span>
        </button>
      </div>
    </div>
  );
}

export default BigArticleCard;
