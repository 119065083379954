
import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { confirmResetPassword} from "aws-amplify/auth";
import { useLocation } from 'react-router-dom';
export default function SignUp() {
  const navigate = useNavigate();
  let location = useLocation();

  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    email: location.state.email,
    newPassword: "",
    code: "",
  
  });

  const handleChange = (e) => {
   
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleReset = async (e) => {
   
    e.preventDefault();
    const { email,code,newPassword } = formData;
  
  
    console.log("pressed")
 
  
    try {
     await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword: newPassword
      });
      navigate("/login")
    
    } catch (error) {
      console.log('error signing up:', error);
      setError('Oops: ' + error.message);

    }

  }
   return (
    <>
    <Header/>
      <div className="flex flex-col flex-1 min-h-full justify-center px-6 py-8 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm flex flex-col items-center justify-center">
          <a href="/">

          <h2 className="mt-4 leading-9 text-center text-2xl font-bold tracking-tight text-black">
           Reset Your Password
          </h2>
          </a>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-[520px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-3xl sm:px-12">
            <form className="space-y-6" onSubmit={handleReset}>
            <div>
                <div className="flex justify-between text-center">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading 6 text-black"
                  >
                
                  </label>
                </div>

                <div className="mt-2">
                  <input
                    value={formData.email}
                    disabled
                    id="email"
                    name="email"
                    type="String"
                    required
                    className="block sm:text-sm sm:leading-6 w-full rounded-full py-1.5 text-black shadow-sm ring-1 ring-owl-purple border-0
                      focus:ring-2 focus:ring-inset ring-inset focus:ring-owl-purple"
                    placeholder="Email"
                  
                  />
                </div>
              </div>
            <div>
                <div className="flex justify-between text-center">
                  <label
                    htmlFor="new-password"
                    className="block text-sm font-medium leading 6 text-black"
                  >
                
                  </label>
                </div>

                <div className="mt-2">
                  <input
                    value={formData.code}
                    onChange={handleChange}
                    id="code"
                    name="code"
                    type="String"
                    required
                    className="block sm:text-sm sm:leading-6 w-full rounded-full py-1.5 text-black shadow-sm ring-1 ring-owl-purple border-0
                      focus:ring-2 focus:ring-inset ring-inset focus:ring-owl-purple"
                    placeholder="Code"
                  
                  />
                </div>
              </div>

              <div>
                <div className="flex justify-between text-center">
                  <label
                    htmlFor="newPassword"
                    className="block text-sm font-medium leading 6 text-black"
                  >
                    Create New Password
                  </label>
                </div>

                <div className="mt-2">
                  <input
                    value={formData.newPassword}
                    onChange={handleChange}
                    id="newPassword"
                    name="newPassword"
                    type="password"
                    autoComplete="new-password"
                    required
                    className="block sm:text-sm sm:leading-6 w-full rounded-full py-1.5 text-black shadow-sm ring-1 ring-owl-purple border-0
                      focus:ring-2 focus:ring-inset ring-inset focus:ring-owl-purple"
                  />
                </div>
              </div>
             
             
              <div>
              {error && <div className="text-red-500 mb-4">{error}</div>}

                <button
                  type="submit"
                  className="flex w-full rounded-full justify-center bg-violet-500 px-3 py-1.5 text-sm text-white font-semibold leading-6 shadow-sm hover:bg-violet-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-owl-purple"
                >
                  Reset
                </button>
              </div>
             
            </form>

          </div>
        </div>
        <div className="mt-8 text-center text-sm text-gray-500">
          Already have an account?{" "}
          <Link
            to="/login"
            className="leading-6 font-semibold text-sm text-violet-500 hover:text-violet-600"
          >
            Login
          </Link>
        </div>
      </div>
      <Footer/>
    </>
  );
}