import React from 'react';
import ArticleCard from './ArticleCard';
import BigArticleCard from './BigArticleCard';
import '../pages/learn/ArticleGrid.css';
import thelogo from '../images/owlPeriwinkle.webp';
import tempImage from '../images/templateimage.webp'
import micro from '../images/microdermabrasionAnimated.webp';

// array of articles
// redirects to google for now
const articles = [
  {
    image: tempImage,
    title: 'The SearchOwl Story',
    description: 'Discover who we are, what we stand for, and our future',
    author: 'By SearchOwl',
    redirectUrl: './blog/searchowl-story',
  },
  {
    image: thelogo,
    title: 'What is SearchOwl?',
    description: 'The new hottest skincare AI-powered search tool',
    author: 'SearchOwl',
    redirectUrl: './blog/what-is-searchowl',
  },
  {
    image: micro,
    title: 'Myths of Microdermabrasion',
    description: 'The myths and truths of cosmetics',
    author: 'SearchOwl',
    redirectUrl: './blog/myths-of-microdermabrasion',
  },
];

// returns the grid of articles
// big article for the first article
// second to fourth articles are normal cards
function ArticleGrid() {
  return (
    <div className="article-grid">
      <div className="article-large">
        <BigArticleCard {...articles[0]} />
      </div>
      <div className="article-rows">
        <ArticleCard {...articles[1]} />
        <ArticleCard {...articles[2]} />
      </div>
    </div>
  );
}

export default ArticleGrid;