import React, { useState } from 'react';
import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";
import "../pages/home/styles.css";

import splotch_1 from "../images/splotch_1.webp";
import splotch_2 from "../images/splotch_2.webp";
import splotch_3 from "../images/splotch_3.webp";

import test_1 from "../images/IMG_5480.webp";
import test_2 from "../images/pexels-steve-1774986.webp";
import test_3 from "../images/IMG_5478.webp";

import { motion } from "framer-motion";
import { fetchUserAttributes } from "aws-amplify/auth";

const imagesToPreLoad = [
  "../images/splotch_2.webp",
  "../images/splotch_3.webp",
  "../images/pexels-steve-1774986.webp",
  "../images/IMG_5478.webp"
]

function preloadImages(urls) {
  urls.forEach(url => {
    const img = new Image();
    img.src = url;
  });
}

preloadImages(imagesToPreLoad)




function ProcessName() {
  const [givenName, setGivenName] = useState(null);

  const updateGivenName = async () => {
    //fetches user's name from cognito record
    try {
      const userAttributes = await fetchUserAttributes();
      setGivenName(userAttributes.name);
    } catch (err) {
      console.log(err);
    }
  };

  updateGivenName();



  if (givenName == null){
    return "YOUR"
  }
  const firstSpaceIndex = givenName.indexOf(' ');
  
  // If there's no space, return the whole string with a space at the beginning
  if (firstSpaceIndex === -1) {
    return ' ' + givenName;
  }
  
  // Get the substring up to the first space
  const result = givenName.substring(0, firstSpaceIndex);
  
  // Add a space at the beginning and return
  return result.toUpperCase() + "'S";
}



const HomePage = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeIn" }}
      className="flex flex-col min-h-screen"
    >
      <Header />
      <main className="flex-grow flex flex-col bg-[#f7f4ee]">
        <div className="content-wrapper mx-auto w-11/12 px-4flex-grow flex flex-col">
          <div className="grid grid-cols-12 grid-rows-5 gap-6 flex-grow lg:grid-rows-3">
            <a
              href="/search"
              className="col-span-12 lg:col-span-8 row-span-2 lg:row-span-3 rounded-lg overflow-hidden zoom-container glow-on-hover"
            >
              <div
                className="relative p-8 h-full bg-cover hover:shadow-xl rounded-xl shadow-[rgba(0,_0,_0,_0.2)_0px_60px_40px_-7px]"
                style={{
                  backgroundImage: `url(${test_3})`,
                  backgroundPosition: "center 65%",
                  backgroundSize: "cover",
                  backgroundAttachment: "local",
                }}
              >
                <h2 className="card-title-pick text-5xl sm:text-5xl md:text-6xl lg:text-5xl xl:text-8xl">
                  ADVANCED SEARCH
                </h2>
              </div>
            </a>
            <a
              href="/foryou"
              className="col-span-12 lg:col-span-4 row-span-2 rounded-lg overflow-hidden zoom-container glow-on-hover"
            >
              <div
                className="relative h-full bg-cover flex justify-end p-8"
                style={{
                  backgroundImage: `url(${test_2})`,
                  backgroundPosition: "right 1% bottom 55%",
                  backgroundSize: "cover",
                  backgroundAttachment: "local",
                }}
                href="/foryou"
              >
                <div className="flex flex-col card-title-pick text-5xl sm:text-5xl md:text-5xl lg:text-6xl xl:text-7xl text-right pr-5">
                  <span>
                  {ProcessName()} PICKS
                  </span>
                </div>
              </div>
            </a>
            <a
              href="/about"
              className="col-span-6 lg:col-span-2 row-span-1 rounded-lg overflow-hidden zoom-container glow-on-hover"
            >
              <div
                className="relative p-6 h-full bg-cover"
                style={{
                  backgroundImage: `url(${splotch_2})`,
                  backgroundPosition: "center 75%",
                  backgroundSize: "cover",
                  backgroundAttachment: "local",
                }}
              >
                <h3 className="card-title-about text-2xl sm:text-5xl md:text-4xl mb-4 ml-4">
                  ABOUT US
                </h3>
              </div>
            </a>
            <a
              href="/quiz"
              className="col-span-6 lg:col-span-2 row-span-1 rounded-lg overflow-hidden zoom-container glow-on-hover"
            >
              <div
                className="relative p-6 h-full bg-cover"
                style={{
                  backgroundImage: `url(${splotch_3})`,
                  backgroundPosition: "75% center",
                  backgroundSize: "cover",
                  backgroundAttachment: "local",
                }}
              >
                <h3 className="card-title-learn text-2xl sm:text-5xl md:text-4xl mb-4 ml-4">
                  QUIZ
                </h3>
              </div>
            </a>
          </div>
        </div>
      </main>
      <Footer />
    </motion.div>
  );
};

export default HomePage;