import React from 'react';
import '../pages/learn/BlogPage1.css';
import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";
import tempImage from '../images/templateimage.webp';

function BlogPage1() {
  return (
    <>
      <Header />
      <div className="blog-page">
        <header className="blog-Header">
          <div className="header-image-container">
            <img src={tempImage} alt="The SearchOwl Story" className="header-image" />
          </div>
        </header>
        <section className="blog-content">
          <h1>The SearchOwl Story</h1>
          <h3>Early Beginnings</h3>
          <p>
            SearchOwl is a personalized search platform for the skincare industry. We’ve been working to 
            connect and empower consumers and vendors since March 2023.
          </p>
          <p>
            SearchOwl was born out of a dorm room at Case Western Reserve University, in Cleveland, Ohio. Four 
            roommates imagined a better way to search for products online. We were sick of advertising, tired 
            of doom-scrolling through products and frustrated with scouring ingredient lists and descriptions. 
            We imagined an advanced search through an advertisement-free collection of products. This search 
            engine would learn your preferences over time, providing more and more accurate recommendations. 
            So, we got to work…
          </p>
          <h3>Our Development</h3>
          <p>
            Over the next few months, very smart people told us to focus our efforts elsewhere. “There’s a 
            reason e-commerce works this way,” they said, “platforms need advertising.” “AI-powered search is 
            way too expensive… You’ll never be able to afford to run this platform.” We weren’t convinced and we 
            continued to work. We knew that people were becoming increasingly aware of the nasty ingredients 
            in skincare, and we believed these people deserved a place to find products that meet their needs. 
          </p>
          <p>
            We spoke with countless people while planning our project. We met students who were reading 
            chemical research articles, and spending hours scrolling – looking for products with clean 
            ingredient lists. We met parents who were concerned about the products they were giving to their 
            children, but unsure of what to do. And we met activists, passionate about eradicating animal 
            testing, but frustrated with search bars that couldn’t avoid these brands. 
          </p>
          <p>
            Along the way, we also talked to the entrepreneurs behind small skincare brands. We found that 
            advertising and platform fees hurt them too, and they needed a place to sell their products. 
            Many entrepreneurs start skincare brands after experiencing problems themselves, crafting 
            products that meet their needs. The e-commerce industry was limiting their reach, and 
            preventing them from helping people with the same problems. 
          </p>
          <p>
            With entrepreneur and shopper feedback in mind, we designed our platform and launched a prototype 
            in December 2023. We are continuing to improve our search and include more brands, dedicated to 
            fully launching our platform. Because shoppers and small businesses need it. If you’re a skincare 
            brand, please reach out… We’d love to include your products. And whoever you are, please, spread 
            the word. The e-commerce industry needs change, and we’re committing to doing better. 
          </p>
        </section>
      </div>
      <br />
      <Footer />
    </>
  );
}

export default BlogPage1;
