import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";
import img from "../images/policy.webp";

export default function Privacy() {
  return (
    <>
      <Header />
      <div className="flex flex-col-1 min-h-[100dvh]">
        <main className="flex-1">
          <section>
            <div className="relative">
              <img
                src={img}
                alt="Privacy Policy"
                className="w-full h-[300px] object-cover rounded-t-lg"
              />
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="container px-4 md:px-6">
                  <div className="mx-full text-center">
                    <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-black mb-8">
                      Privacy Policy
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="max-w-6xl mx-auto px-4 md:px-6">
              <div className="text-black">
                <div className="space-y-6 text-lg">
                  <p className="mt-4">
                    Thank you for using Search Owl, a search engine designed to
                    help you find the best products based on your natural
                    language search queries. We are committed to protecting your
                    privacy and ensuring the security of your personal
                    information. This Privacy Policy explains how we collect,
                    use, disclose, and protect the information you provide to us
                    through the use of our Site. By accessing or using the Site,
                    you consent to the practices described in this Privacy
                    Policy.
                  </p>
                  <div className="space-y-4">
                    <p>Effective Date: 7/12/2023</p>
                    <h2 className="text-2xl font-bold text-left">
                      Information We Collect
                    </h2>
                    <p className="text-left">1.1 Personal Information:</p>
                    <ul className="list-disc pl-6 space-y-2">
                      <li>
                        We may collect personal information from Users in
                        various ways, including but not limited to when Users
                        visit our website, register on the website, place an
                        order, subscribe to our newsletter, respond to a survey,
                        fill out a form, and in connection with other
                        activities, services, features, or resources we make
                        available on our website. The personal information that
                        we may collect includes, but is not limited to, your
                        name, email address, postal address, and phone number.
                      </li>
                    </ul>
                    <p className="text-left">1.2 Non-Personal Information:</p>
                    <ul className="list-disc pl-6 space-y-2">
                      <li>
                        We may collect non-personal information about Users
                        whenever they interact with our website. This
                        non-personal information may include the browser name,
                        the type of computer or device used, technical
                        information about Users' means of connection to our
                        website (e.g., the operating system, internet service
                        providers utilized, and other similar information), and
                        other similar information.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="space-y-8 text-lg py-5">
                  <div className="space-y-4">
                    <h2 className="text-2xl font-bold text-left">
                      How We Use Collected Information
                    </h2>
                    <p className="text-left">2.1 Personal Information:</p>
                    <p className="text-left">
                      We may collect and use personal information for the
                      following purposes:
                    </p>

                    <ul className="list-disc pl-6 space-y-2">
                      <li>
                        To improve customer service: Information you provide
                        helps us respond to your customer service requests and
                        support needs more efficiently.
                      </li>
                      <li>
                        To personalize user experience: We may use information
                        in the aggregate to understand how our Users as a group
                        use the services and resources provided on our website.
                      </li>
                      <li>
                        To improve our website: We continually strive to improve
                        our website offerings based on the information and
                        feedback we receive from you.
                      </li>
                      <li>
                        To process transactions: We may use the information
                        Users provide about themselves when placing an order
                        only to provide service to that order. We do not share
                        this.
                      </li>
                      <li>
                        To send periodic emails: We may use the email address to
                        send User information and updates pertaining to their
                        order. It may also be used to respond to their
                        inquiries, questions, and/or other requests. If User
                        decides to opt-in to our mailing list, they will receive
                        emails that may include company news, updates, related
                        product or service information, etc.
                      </li>
                    </ul>
                    <p className="text-left">2.2 Non-Personal Information:</p>
                    <p className="text-left">
                      We may collect and use non-personal information for the
                      following purposes:
                    </p>
                    <ul className="list-disc pl-6 space-y-2">
                      <li>
                        To analyze trends: We may use non-personal information
                        to analyze trends, administer the site, track User
                        movements around the site, and gather demographic
                        information about our User base as a whole.
                      </li>
                      <li>
                        To improve our website: We may use feedback you provide
                        to improve our products and services.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="space-y-8 text-lg py-5">
                  <div className="space-y-4">
                    <h2 className="text-2xl font-bold text-left">
                      Sharing Of Information
                    </h2>
                    <p className="text-left">
                      3.1 Generalized Data and Analytics:
                    </p>
                    <p className="text-left">
                      We may share generalized data and analytics with third
                      parties for marketing, advertising, or other uses. This
                      information does not identify individual Users and is used
                      for statistical purposes.
                    </p>
                    <p className="text-left">
                      3.2 No Sale of Personalized Information:
                    </p>
                    <p className="text-left">
                      We do not sell, trade, or rent information or data that is
                      tied with personal identification.
                    </p>
                  </div>
                </div>

                <div className="space-y-8 text-lg py-5">
                  <div className="space-y-4">
                    <h2 className="text-2xl font-bold text-left">
                      Data Security
                    </h2>
                    <p className="text-left">
                      We take reasonable measures to protect the information we
                      collect from unauthorized access, loss, misuse,
                      disclosure, alteration, or destruction. However, no method
                      of transmission or electronic storage is completely
                      secure, and we cannot guarantee absolute security.
                    </p>
                  </div>
                </div>

                <div className="space-y-8 text-lg py-5">
                  <div className="space-y-4">
                    <h2 className="text-2xl font-bold text-left">
                      Third-Party Websites
                    </h2>
                    <p className="text-left">
                      The Site may contain links to third-party websites. Please
                      note that we are not responsible for the privacy practices
                      or content of these websites. We encourage you to review
                      the privacy policies of those third parties before
                      providing any personal information.
                    </p>
                  </div>
                </div>

                <div className="space-y-8 text-lg py-5">
                  <div className="space-y-4">
                    <h2 className="text-2xl font-bold text-left">
                      Changes to this Privacy Policy
                    </h2>
                    <p className="text-left">
                      We reserve the right to update or modify this Privacy
                      Policy at any time without prior notice. Any changes to
                      this Privacy Policy will be effective immediately upon
                      posting the updated version on the Site. Your continued
                      use of the Site after any modifications to this Privacy
                      Policy constitutes your acceptance of such changes.
                    </p>
                  </div>
                </div>

                <div className="space-y-8 text-lg py-5">
                  <div className="space-y-4">
                    <h2 className="text-2xl font-bold text-left">Contact Us</h2>
                    <p className="text-left">
                      If you have any questions, concerns, or requests regarding
                      this Privacy Policy or the practices described herein,
                      please contact us at support@searchowl.us.
                    </p>
                  </div>
                </div>

                <div className="space-y-1 text-lg">
                  <div className="space-y-1">
                    <p className="text-center">
                      By using the Site, you acknowledge that you have read and
                      understood this Privacy Policy and agree to be bound by
                      its terms and conditions.
                    </p>
                    <p className="text-center">
                      This Privacy Policy is effective as of the date mentioned
                      above and supersedes any previous version.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </>
  );
}
