import React from 'react';
import '../pages/learn/BlogPage3.css';
import micro from '../images/microdermabrasionAnimated.webp';
import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";

function BlogPage3() {
  return (
    <>
      <Header />
      <div className="blog-page">
        <header className="blog-Header">
          <div className="header-image-container">
            <img src={micro} alt="Myths of Microdermabrasion" className="header-image" />
          </div>
        </header>
        <section className="blog-content">
        <h1>Myths of Microdermabrasion</h1>
        <p>
        Microdermabrasion or MDA, is a non-invasive, non-chemical cosmetic procedure used to create a 
        smoother, brighter, and more taut appearance to the skin. Although this is the most commonly 
        performed non-surgical cosmetic treatment in the United States as of 2023, there is still some 
        stigma around it, which may cause a prospective patient to shy away or avoid it as a whole. 
        Below are five myths about microdermabrasion and why they have been proven false.
        </p>
        <p>
        But first, let's dive into what microdermabrasion actually is and how it may be beneficial for your skin.
        A Microdermabrasion procedure uses a hand-held tool to propel abrasive crystals against the skin, removing 
        the epidermis's stratum corneum layer (outermost layer). This process decreases sebum levels directly 
        after the procedure and promotes collagen growth, cell turnover, and increased wound healing. 
        The treatment can be used to treat anything from photoaging and wrinkles to hyperpigmentation and 
        acne scars and leaves patients with the appearance of younger, smoother, and healthier-looking skin.
        </p>
        <div className="separator"></div>
        <p>
            <strong>Myth:</strong> Microdermabrasion procedures hurt.
        </p>
        <p>
            <strong>Truth:</strong> This procedure includes running a handheld piece over the skin while 
            crystals are propelled at the skin in a controlled stream. This stream is very gentle and only 
            comes in contact with the outermost layer of the skin. Microdermabrasion treatments are non-invasive,
             non-chemical, and non-surgical; therefore, many patients report feeling relaxed and that the feeling 
             is similar to that of a facial or massage.
        </p>
        <div className="separator"></div>
        <p>
            <strong>Myth:</strong> Each session is long.
        </p>
        <p>
            <strong>Truth:</strong> Often called a “lunchtime treatment,” each microdermabrasion session only 
            takes around 15-30 minutes. This session is recommended to be repeated around 6-12 times 
            per year, but this number can vary depending on skin type and skin goals.
        </p>
        <div className="separator"></div>
        <p>
            <strong>Myth:</strong> It is not a safe procedure.
        </p>
        <p>
            <strong>Truth:</strong> Microdermabrasion treatments are considered extremely safe as the procedure 
            only comes in contact with the outermost layer of the skin; therefore, the risk of infection or wounds 
            is almost entirely eliminated. When executed by a professional, microdermabrasion has an excellent safety record.
        </p>
        <div className="separator"></div>
        <p>
            <strong>Myth:</strong> Results take a long time.
        </p>
        <p>
            <strong>Truth:</strong> Although the best option to see long-lasting results is repetitive sessions, 
            results such as the appearance of lighter, brighter, and smoother skin can be seen directly following 
            each procedure. As the skin renews itself every 30 days, repeat treatments are necessary to maintain 
            a bright and smooth complexion. Each treatment is used to not only remove old imperfections but improve 
            skin texture overall to create a more sustainable and smooth base.
        </p>
        <div className="separator"></div>
        <p>
            <strong>Myth:</strong> It is not truly beneficial to your skin.
        </p>
        <p>
            <strong>Truth:</strong> Exfoliation is one of the most important steps towards smoother, brighter skin. 
            The procedure promotes skin cell renewal, collagen growth, and elastin production, leaving the skin 
            with a smoother and younger appearance. Doing so eliminates any old cells and debris blocking the renewal 
            of skin cells and can avoid the same happening in the future. Although serums or other exfoliators are a 
            great place to start, microdermabrasion treatments are a more long-lasting method that can result in a 
            more permanent smoothing and brightening of the skin.
        </p>
        </section>
      </div>
      <br></br>
      <Footer />
    </>
  );
}

export default BlogPage3;