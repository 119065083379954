/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserInformation = /* GraphQL */ `
  query GetUserInformation($id: ID!) {
    getUserInformation(id: $id) {
      id
      gender
      dob
      skinTone
      skinType
      skinIssues
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserInformation = /* GraphQL */ `
  query ListUserInformation(
    $filter: ModelUserInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserInformation(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        gender
        dob
        skinTone
        skinType
        skinIssues
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
