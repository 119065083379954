const suggestions = {
    title: "Hello",
    animated: {
        first: ' "Face wash for oily skin under $25" ',
        second: ' "Moisturizers for dry skin with Glycerin and Hyaluronic acid"',
        third: ' "Tinted sunscreen" ',
        fourth: ' "Reef-friendly facial cleanser" '
    },
    description: "What can we help you find?",
};

export {
    suggestions
};
