import React, { useState, useEffect } from 'react';
import '../pages/profile/ProfilePage.css';
import searchOwlLogo from "../images/owlPeriwinkle.webp";
import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { getCurrentUser, signOut } from "aws-amplify/auth";
import { fetchUserAttributes } from "aws-amplify/auth";
import { client } from "../utils/api-client.js";
import { createUserInformation, updateUserInformation } from '../graphql/mutations.js';

import * as queries from "../graphql/queries.js";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { input, select } from '@nextui-org/react';


function processString(inputString) {
  // Find the index of the first space

  if (inputString == null){
    return ""
  }
  const firstSpaceIndex = inputString.indexOf(' ');
  
  // If there's no space, return the whole string with a space at the beginning
  if (firstSpaceIndex === -1) {
    return ' ' + inputString;
  }
  
  // Get the substring up to the first space
  const result = inputString.substring(0, firstSpaceIndex);
  
  // Add a space at the beginning and return
  return ' ' + result;
}


function ProfilePage() {
  const [givenName, setGivenName] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [skinIssues, setSkinIssues] = useState([]);
  const [selectedSkinTone, setSelectedSkinTone] = useState("");
  const [button, setButton] = useState("Save");
  const [settingsData, setSettingsData] = useState([]);

  const skinIssuesList = [
    "acne",
    "discoloration",
    "aging-prevention",
    "oiliness",
    "dryness",
    "redness",
    "dark-spots",
    "dark-circles",
    "hyperpigmentation",
    "eczema",
    "none",
    "prefer-not-to-say",
  ];

  const skinTonesList = [
    { name: "Vanilla", color: "#FDF0D5", textColor: "#000000" },
    { name: "Alabaster", color: "#F5E6D8", textColor: "#000000" },
    { name: "Oatmeal", color: "#E6D2BF", textColor: "#000000" },
    { name: "Jasmine", color: "#FBE0C5", textColor: "#000000" },
    { name: "Buff", color: "#F1D6BA", textColor: "#000000" },
    { name: "Ivory", color: "#F5D7B5", textColor: "#000000" },
    { name: "Bisque", color: "#F7CBA4", textColor: "#000000" },
    { name: "Warm Beige", color: "#F2C5A0", textColor: "#000000" },
    { name: "Golden Tan", color: "#F4B692", textColor: "#000000" },
    { name: "Latte", color: "#DFA984", textColor: "#000000" },
    { name: "Sand", color: "#D8C1A5", textColor: "#000000" },
    { name: "Caramel", color: "#CBA58B", textColor: "#000000" },
    { name: "Honey Beige", color: "#D59C72", textColor: "#000000" },
    { name: "Gingerbread", color: "#B5886A", textColor: "#000000" },
    { name: "Cappuccino", color: "#B98364", textColor: "#000000" },
    { name: "Chestnut", color: "#BB8557", textColor: "#FFFFFF" },
    { name: "Mocha", color: "#A26A45", textColor: "#FFFFFF" },
    { name: "Bronze", color: "#976846", textColor: "#FFFFFF" },
    { name: "Cocoa", color: "#8E5033", textColor: "#FFFFFF" },
    { name: "Terracotta", color: "#7F4B2C", textColor: "#FFFFFF" },
    { name: "Toffee", color: "#5E3524", textColor: "#FFFFFF" },
    { name: "Espresso", color: "#4F2A1C", textColor: "#FFFFFF" },
    { name: "Hazelnut", color: "#3F2316", textColor: "#FFFFFF" },
    { name: "Pecan", color: "#291508", textColor: "#FFFFFF" },
    { name: "Java", color: "#160A06", textColor: "#FFFFFF" },
  ];

  const updateGivenName = async () => {
    //fetches user's name from cognito record
    try {
      const userAttributes = await fetchUserAttributes();
      setGivenName(userAttributes.name);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchRecord = async () => {
    try {
      const currentUser = await getCurrentUser();
      
      if (!currentUser || !currentUser.userId) {
        throw new Error('Unable to get current user ID');
      }
      
      const res = await client.graphql({ 
        query: queries.getUserInformation,
        variables: { id: currentUser.userId }
      });
      
      return res.data.getUserInformation;
    } catch (e) {
      console.error('Error fetching user information:', e);
      throw e;
    }
  };

  const addSettings = async (e) => {
    //adds record to database
    setButton("...");
    e.preventDefault();

    const record = await fetchRecord();

    if (record) {
      // if record exists

      //if user already exists then update their record in the database
      const recordID = record.id;
      updateRecord(e, recordID);
    } else {
      // else create new record

      createRecord(e);
    }
  };

  async function createRecord(e) {
    //create settings record
    e.preventDefault();
    const { target } = e;

    const currentUser = await getCurrentUser();
    const userAttributes = await fetchUserAttributes();

    try {
      await client.graphql({
        query: createUserInformation,
        variables: {
          input: {
            id: userAttributes.sub,
            name: userAttributes.name,
            gender: target.gender.value || null,
            dob: selectedDate || null,
            skinTone: selectedSkinTone || null,
            skinType: target.skintype.value || null,
            skinIssues: skinIssues || [],
          },
        },
      });
      setButton("Saved");
      setTimeout(function () {
        setButton("Save");
      }, 5000);
    } catch (err) {
      console.log(err);
    }
  }

  async function updateRecord(e, id) {
    //update record function
    e.preventDefault();
    const { target } = e;
    const userAttributes = await fetchUserAttributes();
    const email = userAttributes.email;

    try {
      await client.graphql({
        query: updateUserInformation,
        variables: {
          input: {
            id: id,
            name: userAttributes.name,
            gender: target.gender.value || null,
            dob: new Date(selectedDate).toISOString().substring(0,10) || null,
            skinTone: selectedSkinTone || null,
            skinType: target.skintype.value || null,
            skinIssues: skinIssues || [],
          },
        },
      });
      setButton("Saved");
      setTimeout(function () {
        setButton("Save");
      }, 5000);
    } catch (err) {
      console.log(err);
      if (err instanceof RangeError){
        setButton("Birth Date Chosen is Invalid")
      }
      else{
        setButton("An Error Occurred While Saving")
      }
   }
  }

  //if record of user exists, set settingsData accordingly for population
  const populateSettings = async () => {
    const record = await fetchRecord();
    if (record) {
      //if a record exists
      setSettingsData(record);
      setSelectedSkinTone(record.skinTone);
      setSkinIssues(record.skinIssues);
    }
  };

  useEffect(() => {
    populateSettings();
    updateGivenName();
  }, []);

  const handleSkinIssuesChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      if (value === "none" || value === "prefer-not-to-say") {
        setSkinIssues([value]);
      } else {
        setSkinIssues((prev) => [
          ...prev.filter(
            (issue) => issue !== "none" && issue !== "prefer-not-to-say"
          ),
          value,
        ]);
      }
    } else {
      setSkinIssues((prev) => prev.filter((issue) => issue !== value));
    }
  };

  const handleSkinToneChange = (tone) => {
    setSelectedSkinTone(tone.name);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
    >
      <Header />
      <br />
      <div className="profile-page">
        <motion.div
          className="mask-container"
          initial={{ clipPath: "inset(0% 100% 0% 0%)" }}
          animate={{ clipPath: "inset(0% 0% 0% 0%)" }}
          transition={{ duration: 4, ease: "easeInOut" }}
        >
          <h2>Hello  {processString(givenName)}...</h2>
        </motion.div>

        <form className="profile-form" onSubmit={addSettings}>
          {/* Gender */}
          <label htmlFor="gender">Gender</label>
          <select id="gender" name="gender">
            <option
              value={settingsData.gender ? settingsData.gender : ""}
              hidden
              selected="selected"
              disabled
            >
              {settingsData.gender ? settingsData.gender : ""}
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
            <option value="prefer-not-to-say">Prefer not to say</option>
          </select>

          {/* Birthdate */}
          <label htmlFor="birthdate">Date of Birth</label>
          <div className="datepicker-container">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                name="date"
                value={selectedDate ? selectedDate : settingsData.dob}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                onChange={(date) =>
                  setSelectedDate(date) //just the date part
                }
              />
            </LocalizationProvider>
          </div>

          {/* Skin Tone */}
          <label htmlFor="skintone">Skin Tone</label>
          <div id="skintone" className="checkbox-group">
            {skinTonesList.map((tone) => (
              <div
                key={tone.name}
                className={`skin-tone-box ${
                  selectedSkinTone === tone.name ? "selected" : ""
                }`}
                style={{ backgroundColor: tone.color, color: tone.textColor }}
                onClick={() => handleSkinToneChange(tone)}
                title={tone.name
                  .replace(/-/g, " ")
                  .replace(/\b\w/g, (char) => char.toUpperCase())}
              >
                &nbsp;
              </div>
            ))}
          </div>

          {/* Skin Type */}
          <label htmlFor="skintype">Skin Type</label>
          <select id="skintype" name="skintype">
            <option value={settingsData.skinType} hidden selected disabled>
              {settingsData.skinType}
            </option>
            <option value="Normal">Normal</option>
            <option value="Dry">Dry</option>
            <option value="Oily">Oily</option>
            <option value="Combination">Combination</option>
            <option value="Sensitive">Sensitive</option>
            <option value="Not-Sure">Not sure</option>
          </select>

          {/* Skin Issues to Address */}
          <label htmlFor="skinissues">Skin Issues Want to Address</label>
          <div id="skinissues" className="checkbox-group-skin-issues">
            {skinIssuesList
              .sort((a, b) => {
                if (a === "none" || a === "prefer-not-to-say") return 1;
                if (b === "none" || b === "prefer-not-to-say") return -1;
                return a.localeCompare(b);
              })
              .map((issue) => (
                <div
                  key={issue}
                  className={`skin-issue-box ${
                    skinIssues.includes(issue) ? "selected" : ""
                  }`}
                  onClick={() =>
                    handleSkinIssuesChange({
                      target: {
                        value: issue,
                        checked: !skinIssues.includes(issue),
                      },
                    })
                  }
                  style={{ backgroundColor: "#f7f4ee", color: "#000000" }}
                >
                  {issue
                    .replace(/-/g, " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase())}
                </div>
              ))}
          </div>

          {/* Save Button */}
          <div className="form-footer">
            <button
              type="submit"
              className="flex w-full rounded-full justify-center bg-violet-500 px-3 py-1.5 text-sm text-white font-semibold leading-6 shadow-sm hover:bg-violet-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-owl-purple"
            >
              {button}
            </button>
          </div>
        </form>
      </div>
      <br />
      <Footer />
    </motion.div>
  );
}

export default ProfilePage;
