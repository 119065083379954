import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import searchOwlLogo from '../images/logo.webp';


const PageContainer = styled.div`
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
`;

const ContentContainer = styled.div`
  text-align: center;
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
`;

const Title = styled.h1`
  color: #333;
`;

const Text = styled.p`
  color: #666;
`;

const Button = styled(Link)`
  display: inline-block;
  padding: 10px 20px;
  background-color: #ccccff;
  color: black;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 1rem;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const CustomImage = styled.img`
  width: 150px;
  height: auto;
  margin-bottom: 1rem;
  justify-content: center;
`;

const NotFound = () => {
  return (
    <PageContainer>
    <ContentContainer>
      <ImageContainer>
        <CustomImage src={searchOwlLogo} alt="SearchOwl Logo" />
      </ImageContainer>
      <Title>Whoops! Page Not Found</Title>
      <Text>Sorry, the page you're looking for seems to have flown away.</Text>
      <Text>Let's help you find your way back!</Text>
      <Button to="/">Return to Homepage</Button>
    </ContentContainer>
  </PageContainer>
  );
};

export default NotFound;