import React, { useState, useEffect } from "react";
import { Button } from "@nextui-org/react";
import { Card, CardBody, CardHeader } from "@nextui-org/card";
import cleanserImage from "../images/yourpicks.webp";
import headerimg from "../images/picksheader.webp";
import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";
import { client } from "../utils/api-client.js";
import * as queries from "../graphql/queries.js";
import { CiLock } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "aws-amplify/auth";
import ReactMarkdown from "react-markdown";

const PicksHeader = () => (
  <section className="relative bg-gradient-to-r from-primary to-secondary py-20 px-4 md:px-6">
    <img
      src={headerimg}
      alt="Header Background"
      className="absolute inset-0 w-full h-full object-cover"
    />
    <div className="absolute inset-0 bg-black opacity-50"></div>
    <div className="relative container mx-auto max-w-4xl text-center z-10">
      <h1 className="text-4xl font-bold text-white mb-4 sm:text-5xl">
        Personalized Skincare Recommendations
      </h1>
      <p className="text-lg text-white/80 mb-8">
        Discover the perfect products for your unique skin type and concerns.
      </p>
    </div>
  </section>
);

const SkinProfileCard = ({ skinProfile }) => {
  const navigate = useNavigate();

  const handleEditProfile = () => {
    navigate("/profile");
  };

  const renderTag = (content) => (
    <span className="bg-gray-200 text-black rounded-md px-2 py-2 text-md inline-block">
      {content}
    </span>
  );

  return (
    <Card className="p-6 shadow-lg rounded-lg">
      <CardBody>
        <h2 className="text-2xl font-bold mb-4">Your Skin Profile</h2>
        <div className="grid gap-4">
          <div className="grid gap-2">
            <h3 className="text-lg font-bold">Skin Type</h3>
            <div>
              {renderTag(skinProfile?.skinType || "Not specified")}
            </div>
          </div>
          <div className="grid gap-2">
            <h3 className="text-lg font-medium">Skin Tone</h3>
            <div>
              {renderTag(skinProfile?.skinTone || "Not specified")}
            </div>
          </div>
          <div className="grid gap-2">
            <h3 className="text-lg font-medium">Skin Concerns</h3>
            <div className="flex flex-wrap gap-2">
              {skinProfile?.skinIssues && skinProfile.skinIssues.length > 0 ? (
                skinProfile.skinIssues.map((issue, index) => (
                  renderTag(
                    issue
                      .replace(/-/g, " ")
                      .replace(/\b\w/g, (char) => char.toUpperCase())
                  )
                ))
              ) : (
                renderTag("Not specified")
              )}
            </div>
          </div>
          {/* <Button
            variant="bordered"
            className="w-full sm:w-auto border-black bg-blue-500 px-5 py-2 rounded-lg text-white"
            onClick={handleEditProfile}
          >
            Edit Skin Profile
          </Button> */}
          <div className="mt-10">
          <Button
            variant="bordered"
            className="w-full border-black bg-blue-500 px-5 py-2 rounded-lg text-white"
            onClick={handleEditProfile}
          >
            Edit Skin Profile
          </Button>
        </div>
        </div>
      </CardBody>
    </Card>
  );
};

const skincareRecommendations = {
  Description: {
    title: "Understanding your skin type",
    Sensitive: "Your skin is easily irritated, prone to redness, and may react strongly to various products. Opt for gentle, fragrance-free, and hypoallergenic skincare products to soothe and protect your skin.",
    Oily: "Your skin is shiny and greasy all over, with larger pores and a tendency to break out. Using oil-free and non-comedogenic products can help manage excess oil and prevent breakouts.",
    Combination: "You have a mix of oily and dry areas, typically with an oily T-zone (forehead, nose, and chin) and drier cheeks. Using a balanced skincare routine that targets both oil control and hydration will work best.",
    Normal: "Your skin feels comfortable and balanced. It is not too oily or too dry and rarely has breakouts. Maintaining a regular skincare routine will help keep your skin healthy.", 
    Dry: "Your skin tends to feel tight, dry, and may appear flaky. It is often prone to irritation and sensitivity. Using hydrating and gentle skincare products can help maintain moisture."
  },
  Cleansers: {
    title: "**Cleansers**",
    Sensitive: "Choose a mild, fragrance-free cleanser that won't irritate your skin. Look for cleansers with soothing ingredients like aloe vera, chamomile, or oat extract.",
    Oily: "Cleanse your face twice a day with a gentle, foaming cleanser that effectively removes excess oil, dirt, and impurities without stripping your skin. Look for cleansers with salicylic acid or tea tree oil to help control oil production and prevent breakouts.",
    Combination: "A gentle, gel-based or foaming cleanser can help address oiliness in the T-zone (forehead, nose, chin) while being mild enough for drier areas like the cheeks.",
    Normal: "Balance is key. Use a gentle cleanser that removes dirt and impurities without stripping your skin's natural moisture. Opt for a pH-balanced formula that supports your skin's barrier.", 
    Dry: "Choose a mild, hydrating cleanser that doesn't strip your skin of its natural oils. Directly after cleansing, immediately apply moisturizing serums and creams to avoid excessive dryness."
  },
  Moisturizers: {
    title: "**Moisturizers**",
    Sensitive: "Use a hypoallergenic moisturizer that is free of fragrances, dyes, and other potential irritants.",
    Oily: "Opt for a lightweight, oil-free moisturizer that provides hydration without adding excess oil. Gel-based or water-based moisturizers are ideal as they absorb quickly and leave your skin feeling refreshed.",
    Combination: "Use a lightweight, oil-free moisturizer that hydrates your skin without adding excess oil. Gel-based or water-based moisturizers are great options as they provide hydration without feeling heavy or greasy.",
    Normal: "Keep your skin hydrated by using a lightweight, non-greasy moisturizer. Look for one that provides enough hydration without feeling heavy.",
    Dry: "Opt for heavier creams over moisturizers as they are often thicker and more hydrating. Ingredients such as hyaluronic acid, glycerin, ceramides, and shea butter can help deeply hydrate and repair the skin barrier."
  },
  SerumsAndToners: {
    title: "**Serums and Toners**",
    Sensitive: "Exfoliate your skin sparingly—once a week at most—using a very gentle exfoliant. Avoid harsh physical scrubs.",
    Oily: "Consider using a serum with salicylic acid to target oily areas and prevent breakouts.",
    Combination: "Use a hydrating toner after cleansing to balance your skin's moisture levels. Look for toners with ingredients like hyaluronic acid or rose water that hydrate without adding oil, and avoid alcohol-based toners that can dry out your skin.",
    Normal: "Lightweight Serums with hyaluronic acid, vitamin C, or peptides can provide an extra layer of protection and nourishment without overwhelming your skin.", 
    Dry: "Consider layering multiple hydrating products, such as a hydrating toner, serum, and moisturizer. This helps to build up moisture in the skin and creates a stronger barrier against dryness."
  },
  Sunscreen: {
    title: "**Sunscreen**",
    Sensitive: "Choose a formula with zinc oxide or titanium dioxide, which are less likely to cause irritation than chemical sunscreens.",
    Oily: "Want to reduce shine in the sun? Use a broad-spectrum sunscreen that has a mattifying effect. A formula designed for oily skin will help prevent excess shine and breakouts.",
    Combination: "Opt for a formula that controls oil and doesn't clog pores, particularly in the T-zone, but also hydrates and protects the drier areas of your skin. Make sure the sunscreen is also non-comedogenic and lightweight to avoid breakouts.",
    Normal: "Even if your skin isn't prone to dryness or oiliness, UV rays can cause long-term damage and premature aging, so consistent sun protection is key.", 
    Dry: "Use a broad-spectrum sunscreen with at least SPF 30 daily, and consider a sunscreen with added hydrating ingredients to prevent further dryness."
  },
  Other: {
    title: "**Other**",
    Sensitive: "Look for skincare products that contain soothing ingredients like aloe vera, chamomile, calendula, or colloidal oatmeal. These ingredients help calm redness and irritation while nourishing your skin.",
    Oily: "Incorporate a clay or charcoal mask into your routine once or twice a week to absorb excess oil and deep-clean your pores. Choose skincare and makeup products labeled as non-comedogenic, meaning they won't clog your pores. This is essential for preventing breakouts and keeping your skin clear.",
    Combination: "Combination skin requires flexibility, so be prepared to adjust your skincare products and routine as needed.",
    Normal: "If you notice changes such as increased dryness or oiliness to your skin, adjust your routine accordingly. Keeping an eye on how your skin responds to products and environmental factors will help maintain its health over time.", 
    Dry: "Opt for hypoallergenic and fragrance-free products whenever possible to avoid irritation."
  }
};

const LearnMoreCard = ({ skinProfile }) => {
  const navigate = useNavigate();
  
  const handleSearch = () => {
    navigate("/search");
  };

  const renderRecommendations = () => {
    if (!skinProfile || !skinProfile.skinType) {
      return <p>No recommendations available. Please complete your skin profile.</p>;
    }

    const skinType = skinProfile.skinType;
    return Object.entries(skincareRecommendations).map(([category, content]) => (
      <div key={category} className="mb-4">
        <ReactMarkdown className={`bg-gray-200 text-black rounded-md px-2 py-2 text-md inline-block mb-2`}>{content.title}</ReactMarkdown>
        <p>{content[skinType]}</p>
      </div>
    ));
  };

  return (
    <Card className="p-6 shadow-lg rounded-lg relative h-[500px] flex flex-col">
      <CardBody className="flex-grow overflow-hidden flex flex-col">
        <h2 className="text-2xl font-bold mb-4">Learn More About Your Skin</h2>
        <div className="overflow-y-auto flex-grow mb-4">
          {renderRecommendations()}
        </div>
        <div className="flex flex-row w-full gap-4 mt-auto">
          <Button className="flex-1 bg-blue-300 px-5 py-2 rounded-lg text-white">
            Skin Analysis
          </Button>
          <Button
            variant="bordered"
            className="flex-1 border-black bg-blue-500 px-5 py-2 rounded-lg text-white"
            onClick={handleSearch}
          >
            Search for Products
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const addLeadingZero = (value) => {
    return value < 10 ? `0${value}` : value;
  };

  return (
    <div className="flex justify-center space-x-4 text-gray-200">
      {Object.keys(timeLeft).map((interval) => (
        <div key={interval} className="text-center">
          <span className="text-xl font-bold">
            {addLeadingZero(timeLeft[interval])}
          </span>
          <span className="text-xs block">{interval}</span>
        </div>
      ))}
    </div>
  );
};

const ProductCard = ({
  imageSrc,
  title,
  description,
  isLocked,
  unlockDate,
}) => (
  <div className="relative transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-xl rounded-xl shadow-[rgba(0,_0,_0,_0.2)_0px_20px_20px_-7px] overflow-hidden">
    <Card className="h-full">
      <img
        src={imageSrc}
        alt={title}
        width={300}
        height={300}
        className="rounded-t-lg object-cover w-full h-48"
      />
      <CardBody className="p-4">
        <h3 className="text-lg font-semibold mb-2">{title}</h3>
        <p className="text-sm text-muted-foreground">{description}</p>
      </CardBody>
    </Card>
    {isLocked && (
      <div className="absolute inset-0 bg-gray-600 bg-opacity-100 flex flex-col items-center justify-center rounded-xl z-10">
        <CiLock className="text-gray-200 mb-4" size={32} />
        <h3 className="text-lg font-semibold text-gray-200 mb-2 text-center px-2">
          Your Recommended Product
        </h3>
        <p className="text-sm text-gray-300 mb-4">Unlocks in:</p>
        <CountdownTimer targetDate={unlockDate} />
      </div>
    )}
  </div>
);

const ProductSection = ({ title, products, isLocked }) => (
  <section>
    <h2 className="text-2xl font-bold mb-4">{title}</h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {products.map((product, index) => (
        <ProductCard key={index} {...product} isLocked={isLocked} />
      ))}
    </div>
  </section>
);

const FavoriteCard = ({ isLocked, unlockDate }) => (
  <Card className="shadow-xl rounded-lg h-full">
    <main>
      <CardHeader className="text-2xl font-semibold">Your Favorites</CardHeader>
      <CardBody>
        <div className="grid gap-4 pt-4">
          <div className="flex items-center gap-4">
            <img
              src={cleanserImage}
              alt="Product 1"
              width={64}
              height={64}
              className="rounded-lg"
            />
            <div>
              <h3 className="text-lg font-semibold">Hydrating Gel Cream</h3>
            </div>
          </div>
        </div>
      </CardBody>
    </main>
    {isLocked && (
      <div className="absolute inset-0 bg-gray-600 opacity-100 flex flex-col items-center justify-center rounded-lg">
        <CiLock className="text-gray-200 mb-4" size={32} />
        <h3 className="text-lg font-semibold text-gray-200 mb-2">
          Your Favorites
        </h3>
        <p className="text-sm text-gray-300 mb-4">Unlocks in:</p>
        <CountdownTimer targetDate={unlockDate} />
      </div>
    )}
  </Card>
);

export default function YourPicks() {
  const [skinProfile, setSkinProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLocked, setIsLocked] = useState(true);

  const baseDate = new Date("2024-08-30T00:00:00");
  const profileUnlockDate = new Date(
    baseDate.getTime() + 4 * 7 * 24 * 60 * 60 * 1000
  );

  useEffect(() => {
    const fetchSkinProfile = async () => {
      try {
        const currentUser = await getCurrentUser();

        if (!currentUser || !currentUser.userId) {
          throw new Error("Unable to get current user ID");
        }

        const res = await client.graphql({
          query: queries.getUserInformation,
          variables: { id: currentUser.userId },
        });

        const userSettings = res.data.getUserInformation;
        if (userSettings) {
          setSkinProfile({
            skinType: userSettings.skinType,
            skinTone: userSettings.skinTone,
            skinIssues: userSettings.skinIssues,
          });
        }
      } catch (error) {
        console.error("Error fetching skin profile:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSkinProfile();
  }, []);

  const getUnlockDate = (weeks) => {
    return new Date(baseDate.getTime() + weeks * 7 * 24 * 60 * 60 * 1000);
  };

  const cleansers = [
    {
      imageSrc: cleanserImage,
      title: "Gentle Foaming Cleanser",
      description:
        "A nourishing, non-drying formula that removes impurities without stripping the skin.",
      unlockDate: getUnlockDate(6),
    },
    {
      imageSrc: cleanserImage,
      title: "Exfoliating Cleanser",
      description:
        "A gentle exfoliating cleanser to remove dead skin cells and unclog pores.",
      unlockDate: getUnlockDate(8),
    },
    {
      imageSrc: cleanserImage,
      title: "Hydrating Cream Cleanser",
      description:
        "A creamy cleanser that hydrates while effectively removing makeup and impurities.",
      unlockDate: getUnlockDate(10),
    },
  ];

  const moisturizers = [
    {
      imageSrc: cleanserImage,
      title: "Hydrating Gel Cream",
      description:
        "A lightweight, oil-free formula that deeply hydrates without clogging pores.",
      unlockDate: getUnlockDate(6),
    },
    {
      imageSrc: cleanserImage,
      title: "Nourishing Night Cream",
      description:
        "A rich, nourishing cream that replenishes skin's moisture overnight.",
      unlockDate: getUnlockDate(8),
    },
    {
      imageSrc: cleanserImage,
      title: "Barrier Repair Moisturizer",
      description:
        "A moisturizer designed to strengthen and repair the skin's natural barrier.",
      unlockDate: getUnlockDate(10),
    },
  ];

  const serums = [
    {
      imageSrc: cleanserImage,
      title: "Vitamin C Brightening Serum",
      description:
        "A potent formula that helps fade dark spots and discoloration for a more radiant complexion.",
      unlockDate: getUnlockDate(6),
    },
    {
      imageSrc: cleanserImage,
      title: "Hyaluronic Acid Serum",
      description:
        "An ultra-hydrating serum that plumps and moisturizes the skin.",
      unlockDate: getUnlockDate(8),
    },
    {
      imageSrc: cleanserImage,
      title: "Retinol Anti-Aging Serum",
      description:
        "A powerful anti-aging serum that reduces fine lines and improves skin texture.",
      unlockDate: getUnlockDate(10),
    },
  ];

  return (
    <div className="flex flex-col min-h-dvh">
      <Header />
      <PicksHeader />
      <div className="mx-6 sm:mx-8 md:mx-11 lg:mx-14 xl:mx-16 py-8">
        <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-8 pb-12">
          {loading ? (
            <Card className="p-6 shadow-lg rounded-lg">
              <CardBody>
                <p>Loading skin profile...</p>
              </CardBody>
            </Card>
          ) : (
            <SkinProfileCard skinProfile={skinProfile} />
          )}
          <LearnMoreCard skinProfile={skinProfile} />
        </div>
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="lg:w-3/4 space-y-8">
            <div className="space-y-12">
              <ProductSection
                title="Cleansers"
                products={cleansers}
                isLocked={isLocked}
              />
              <ProductSection
                title="Moisturizers"
                products={moisturizers}
                isLocked={isLocked}
              />
              <ProductSection
                title="Serums"
                products={serums}
                isLocked={isLocked}
              />
            </div>
          </div>
          <aside className="lg:w-1/4 space-y-8">
            <FavoriteCard isLocked={isLocked} unlockDate={profileUnlockDate} />
          </aside>
        </div>
      </div>
      <Footer />
    </div>
  );
}
