import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ArticleGrid from './ArticleGrid';
import ContactPage from './Contact';
import BlogPage1 from './BlogPage1';
import '../pages/learn/Learn.css';

import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";

import { motion } from "framer-motion";
import learn from "../images/learn.webp";


const LearnHeader = () => (
  <section className="relative bg-gradient-to-r from-primary to-secondary py-20 mb-12 px-4 md:px-6">
    <img
      src={learn}
      alt="Header Background"
      className="absolute inset-0 w-full h-full object-cover"
    />
    <div className="absolute inset-0 bg-black opacity-50"></div>
    <div className="relative container mx-auto max-w-4xl text-center z-10">
      <h1 className="text-4xl font-bold text-white mb-4 sm:text-5xl">
        Learn More
      </h1>
      <p className="text-lg text-white/80 mb-8">
      Dive into expert insights, tips, and the science behind effective skincare.
      </p>
    </div>
  </section>
);

function App() {
  return (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.75, ease: "easeIn" }}
  >
      <div className="App">
        <Header/>
        <LearnHeader/>
        <main>
          <Routes>
            <Route path="/" element={<ArticleGrid />} />
            <Route path="/blog1" element={<BlogPage1 />} /> 
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </main>
        <Footer/>
      </div>
      </motion.div>
  );
}

export default App;
