import React from 'react';
import '../pages/learn/BlogPage2.css';
import thelogo from '../images/owlPeriwinkle.webp';
import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";

function BlogPage2() {
  return (
    <>
      <Header />
      <div className="blog-page">
        <header className="blog-Header">
          <div className="header-image-container">
            <img src={thelogo} alt="The SearchOwl Story" className="header-image" />
          </div>
        </header>
        <section className="blog-content">
          <h1>What is SearchOwl?</h1>
          <p>
          SearchOwl is a personalized search platform for the skincare industry. We leverage data to connect 
          consumers and vendors through better decision-making. Platforms like Amazon and Ulta create friction
           in the search process. Consumers struggle to find the perfect product thanks to outdated search
            technology. Small brands are hurt by advertising and platform fees. Here’s how SearchOwl is different:
          </p>
          <h3>Advertising and Sponsorships</h3>
          <p>
          Other e-commerce platforms put certain vendors above others, at the expense of vendors and shoppers. 
          The first result shouldn’t go to the highest bidder. At SearchOwl, the first result is the product 
          that best meets your needs. 
          </p>
          <h3>The Search bar</h3>
          <p>
          The default search bar is based on keyword technology. You get 3-4 ‘keywords’ to describe what you’re 
          looking for. For educated, conscious shoppers, this is not enough. Shoppers often want specific brands, 
          types of products, ingredients, sourcing, price ranges, review data, and more! SearchOwl accommodates 
          your requests so that you can ask for…
          </p>
          <p>
          “A facial moisturizer with at least SPF 30 (zinc-based), under $35, with more than 4.5 stars. 
          Must be vegan and reef-friendly”
          </p>
          <p>
          … instead of “SPF Moisturizer.”
          </p>
          <h3>Personalization</h3>
          <p>
          Shoppers have preferences. Maybe you prefer small businesses. Maybe you’re avidly against animal 
          testing. Maybe, you refuse to buy moisturizers with fragrances, because it causes your skin to break 
          out! E-commerce sites collect a lot of data but don’t remember anything about you. SearchOwl learns 
          your preferences over time.
          </p>
          <p>
          The difference is clear. SearchOwl is far better for shoppers and businesses than typical e-commerce 
          sites because it’s our mission. If you’re a skincare brand, please reach out… We’d love to include 
          your products. And whoever you are, please, spread the word. The e-commerce industry needs change, 
          and we’re committing to doing better.
          </p>
        </section>
      </div>
      <br></br>
      <Footer />
    </>
  );
}

export default BlogPage2;