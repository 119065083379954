import React from "react";
import {
  FaInstagram,
  FaLinkedin,
  FaTiktok,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="mt-auto py-4 bg-[#f7f4ee]">
      <div className="container mx-auto flex flex-col items-center justify-between px-2">
        <div className="text-gray-250 flex justify-between items-center mb-4 space-x-20">
          <a
            href="https://www.linkedin.com/company/searchowl-us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="text-2xl md:text-xl sm:text-sm" />
          </a>
          <a
            href="https://www.instagram.com/search_owl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="text-2xl md:text-xl sm:text-sm space-x-3" />
          </a>
          <a
            href=" https://www.tiktok.com/@search_owl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTiktok className="text-2xl md:text-xl sm:text-sm" />
          </a>
        </div>
        <div className="text-gray-400 text-sm md:text-xs text-center">
          <div className="flex space-x-3">
            <a
              href="../legal/disclaimer"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="text-xs">Disclaimer</p>
            </a>
            <a
              href="../legal/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>Privacy Policy</p>
            </a>
            <a
              href="../legal/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>Terms and Conditions</p>
            </a>
            <a
              href="../sitemap"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>Sitemap</p>
            </a>
          </div>

          <span className="mb-4 text-center text-sm md:text-xs text-gray-350 sm:!mb-0">
            SearchOwl © {new Date().getFullYear()}
          </span>
        </div>
      </div>
    </footer>
  );
};
export default Footer;