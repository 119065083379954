import React from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import SignUp from "./components/SignUp";
import Login from "./components/Login";
import ConfirmationPage from "./components/ConfirmationPage";

import { AdvancedSearch } from "./components/AdvancedSearch";
import PasswordReset from './components/PasswordReset';
import ForgotPassword from './components/ForgotPassword';
import AboutUs from "./components/AboutUs";
import Home from "./components/Home";
import { AnimatePresence } from "framer-motion";
import Learn from "./components/Learn"
import Contact from "./components/Contact"
import Blog1 from "./components/BlogPage1"
import Blog2 from "./components/BlogPage2"
import Blog3 from "./components/BlogPage3"
import ProfilePage from "./components/ProfilePage"
import Quiz from "./components/Quiz"
import SearchResults from "./components/SearchResults"
import ProductLandingPage from "./components/ProductLandingPage"
import Disclaimer from "./components/Disclaimer"
import Terms from "./components/Terms"
import Privacy from "./components/Privacy"
import ProtectedRoutes from "./utils/ProtectedRoutes";
import Loading from "./components/Loading"
//import Favorites from "./components/Favorites"
//import { Favorite } from '@mui/icons-material';
import YourPicks from "./components/YourPicks";
import NotFound from "./components/NotFound"
import Favorites from './components/Favorites';

function App() {
  
  return (
    <Router>
      <AnimatePresence>
        <Routes>
        <Route path="" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/confirmation" element={<ConfirmationPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/search" element={<AdvancedSearch/>} />
          <Route path="/blog" element={<Learn/>} />
          {/* <Route path="/favorites" element={<Favorites/>} /> */}

          <Route path="/contact" element={<Contact/>} />
          <Route path="/blog/searchowl-story" element={<Blog1/>} />
          <Route path="/blog/what-is-searchowl" element={<Blog2/>} />
          <Route path="/blog/myths-of-microdermabrasion" element={<Blog3/>} />
          <Route path="/quiz" element={<Quiz />} />
          
          <Route element={<ProtectedRoutes/>} >
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/foryou" element={<YourPicks/>} />
          </Route>
     
          <Route path="/s" element={<SearchResults/>} />
          <Route path="/p/:productName/:productId" element={<ProductLandingPage/>} />
          <Route path ="/legal/privacy" element={<Privacy/>} />
          <Route path ="/legal/disclaimer" element={<Disclaimer/>} />
          <Route path ="/legal/terms" element={<Terms/>} />

          <Route path="/loading" element={<Loading/>} />
          <Route path="/sitemap" element={<Navigate to="/sitemap.xml" replace />} />

          <Route path="*" element={<NotFound/>} />
        </Routes>
      </AnimatePresence>
    </Router>
  );
}

export default App;