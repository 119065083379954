import owl from "../images/owlPeriwinkle.webp"

export default function Component() {
  return (
    <div className="flex h-screen w-full items-center justify-center bg-gray-100 light:bg-gray-900">
      <div className="flex flex-col items-center space-y-4">
        <div className="animate-bounce">
          <img
            src={owl}
            alt="Loading"
            className="h-12 w-12"
          />
        </div>
        <p className="text-gray-500 dark:text-gray-400">Loading...</p>
      </div>
    </div>
  );
}
// export default function Component() {
//     return (
//       <div className="flex h-screen w-full items-center justify-center bg-gray-100 light:bg-gray-900">
//         <div className="flex flex-col items-center space-y-4">
//           <div className="animate-spin">
//             <LoaderPinwheelIcon className="h-12 w-12 text-gray-500 dark:text-gray-400" />
//           </div>
//           <p className="text-gray-500 dark:text-gray-400">Loading...</p>
//         </div>
//       </div>
//     )
//   }
  
  // function LoaderPinwheelIcon(props) {
  //   return (
  //     <svg
  //       {...props}
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       stroke="currentColor"
  //       strokeWidth="2"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     >
  //       <path d="M2 12c0-2.8 2.2-5 5-5s5 2.2 5 5 2.2 5 5 5 5-2.2 5-5" />
  //       <path d="M7 20.7a1 1 0 1 1 5-8.7 1 1 0 1 0 5-8.6" />
  //       <path d="M7 3.3a1 1 0 1 1 5 8.6 1 1 0 1 0 5 8.6" />
  //       <circle cx="12" cy="12" r="10" />
  //     </svg>
  //   )
  // }