import React from "react";
import "../pages/about/style.css";
import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";

import {
  Card,
  Image,
  CardBody,
  CardHeader,
  Tooltip,
} from "@nextui-org/react";

import cullen from "../images/Cullen.webp";
import dale from "../images/Dale.webp";
import jadon from "../images/Jadon.webp";
import ryan from "../images/Ryan.webp";
import priya from "../images/Priya.webp";
import aboutus from "../images/aboutus.webp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { motion } from "framer-motion";

const AboutUsHeader = () => (
  <section className="relative bg-gradient-to-r from-primary to-secondary py-20 mb-12 px-4 md:px-6">
    <div className="absolute inset-0"></div>
    <div className="relative container mx-auto max-w-6xl text-center z-10">
      <h1 className="text-4xl font-bold text-black mb-4 sm:text-5xl">
        Meet the Team
      </h1>
      <p className="text-lg text-black/80 mb-8">
      Born in a Case Western dorm room, SearchOwl came to fruition when a group of students realized how inherently broken online shopping is. Shopping platforms restrict access to brands with big budgets, meaning that small businesses with high-quality products are inaccessible. Shoppers can’t describe the ingredients they do and don’t want in a search bar restricted to a few words. Search results are full of advertisements and ‘sponsored listings’ that don’t match shoppers’ needs. The only thing personalized on shopping sites are the banner ads. But what if there was a better way to search for products - A site that learned your preferences and provided unsponsored results to your advanced searches?
      </p>
      <p className="text-lg text-black/80 mb-8">
      SearchOwl is a personal shopper for skincare. Tell us what you’re looking for, and we’ll give you unsponsored, personalized recommendations. Search from more skincare brands than Ulta and Sephora combined, ad-free, on a website that learns your preferences. We decided that the way we online shop for products is broken, so we created SearchOwl. Join us as we “Curate a stronger shopping paradigm for businesses and users alike.” 
      </p>
    </div>
  </section>
);

const AboutUsDesc = () => (
  <section className="relative bg-gradient-to-r from-primary to-secondary py-20 mb-12 px-4 md:px-6">
    <img
      src={aboutus}
      alt="Header Background"
      className="absolute inset-0 w-full h-full object-cover"
    />
    <div className="absolute inset-0 bg-black opacity-50"></div>
    <div className="relative container mx-auto max-w-4xl text-center z-10">
      <h1 className="text-4xl font-bold text-white mb-4 sm:text-5xl">
        SearchOwl Story
      </h1>
      <p className="text-lg text-white/80 mb-8">
      Discover the passionate team dedicated to revolutionizing your skincare journey.
      </p>
    </div>
  </section>
);

const AboutUs = () => {
  const list = [
    {
      title: "CEO",
      name: "Jadon Wyant",
      img: jadon,
      linkedin: "https://www.linkedin.com/in/jadon-wyant-573a9b21b/",
      bio: "Prior to starting SearchOwl, Jadon ran a swimming blog and corresponding Instagram and Twitter audience with over 1500 total followers, as well as a video game e-commerce store. Jadon is a senior studying Accounting at CWRU.",
    },
    {
      title: "CTO",
      name: "Dale Berkove",
      img: dale,
      linkedin: "https://www.linkedin.com/in/dale-berkove-8817071b4/",
      bio: "Dale is a senior studying Data Science at CWRU. He has considerable experience coding, including game development, coding a mathematics app for elementary school students, and consulting.",
    },
    {
      title: "CMO",
      name: "Priya Francis",
      img: priya,
      linkedin: "https://www.linkedin.com/in/priyatfrancis",
      bio: "Priya is a junior at the University of Miami, currently studying marketing. She has had the privilege of working with various marketing and real estate groups within the Lehigh Valley. Priya also has considerable experience working with multi-media art and graphic design.",
    },
    {
      title: "COO",
      name: "Cullen Combi",
      img: cullen,
      linkedin: "https://www.linkedin.com/in/mark-combi-92bba7210/",
      bio: "Cullen is a senior at CWRU, studying Mechanical Engineering. Cullen has a variety of project management experiences and expertise in solving complex problems in high-uncertainty environments.",
    },
    {
      title: "CFO",
      name: "Ryan Kaiser",
      img: ryan,
      linkedin: "https://www.linkedin.com/in/ryanparkkaiser",
      bio: "Ryan is a senior study Finance and International Business at CWRU. He specializes in financial modeling, forecasting and strategy, and has applied his skills at multiple different corportations and countries.",
    },
  ];

  const handleCardClick = (index) => {
    const bioCard = document.getElementById(`bio-${index}`);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
    >
      <Header/>
      <AboutUsDesc />
      <AboutUsHeader />
      <div className={"about"}>
        {list.map((item, index) => (
          <div key={index} onClick={() => handleCardClick(index)}>
            <div className="flex flex-box container">
              <Tooltip>
                <Card className={"container card group"} width="100%" shadow >
                  <CardBody className="container card content">
                    <Image
                      className="container card content imgBx img opacity-1"
                      src={item.img}
                      width="100%"
                      height="auto"
                    />
                    <CardHeader className="flex justify-center items-center flex-col text-3xl pt-7">
                      {item.name}
                    </CardHeader>
                    <div className="container .card .content">
                      <p>{item.title}</p>
                    </div>
                    <div className="container card content px-3 z-50 absolute opacity-0 transition ease-in-out duration-300 hover:opacity-100">
                      {item.bio}
                      <a
                        href={`${item.linkedin}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon
                          className="py-7"
                          icon={faLinkedin}
                          size="xl"
                        />
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Tooltip>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </motion.div>
  );
};

export default AboutUs;
