import React, { useState, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import Typewriter from "typewriter-effect";
import { suggestions } from "../pages/advanced-search/content-option.js";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";

import { fetchUserAttributes } from "aws-amplify/auth";

function processString(inputString) {
  // Find the index of the first space
  if (inputString == null){
    return ""
  }

  
  const firstSpaceIndex = inputString.indexOf(' ');
  
  // If there's no space, return the whole string with a space at the beginning
  if (firstSpaceIndex === -1) {
    return ' ' + inputString;
  }
  
  // Get the substring up to the first space
  const result = inputString.substring(0, firstSpaceIndex);
  
  // Add a space at the beginning and return
  return ' ' + result;
}

export const AdvancedSearch = () => {
  const [givenName, setGivenName] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  const handleSearch = () => {
    navigate(`/s?q=${encodeURIComponent(searchQuery)}`);
  };

  const getUserAttributes = async () => {
    try {
      const userAttributes = await fetchUserAttributes();
      setGivenName(userAttributes.name);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUserAttributes();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeIn" }}
      className="flex flex-col min-h-screen"
    >
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <HelmetProvider>
        <Header />
        <div className="flex-grow flex flex-col">
          <div className="flex-grow flex flex-col justify-center items-center p-8">
            <div className="intro_sec d-block d-lg-flex align-items-center">
              <div className="text order-2 order-lg-1 h-100 d-lg-flex justify-content-center">
                <div className="align-self-center text-center">
                  <div className="intro mx-auto">
                    <h1 className="fluidz-48 mb-1x text-3xl">
                      <span className="text-7xl text-transparent sm:text-8xl md:text-8xl lg:text-8xl xl:text-9xl bg-clip-text bg-[radial-gradient(ellipse_at_bottom_left,_var(--tw-gradient-stops))] from-[#b9a5a1] to-[#69443C] py-4">
                        {suggestions.title}{ processString(givenName)},
                      </span>{" "}
                      <p className="mb-2x text-4xl py-2 sm:text-5xl md:text-6xl lg:text-6xl xl:text-8xl text-[#b9a5a1] md:py-5 lg:py-4 xl:py-5">
                        {suggestions.description}
                      </p>
                      <p className="text-xl mt-4x mb-4 sm:text-3xl md:text-4xl lg:text-3xl xl:text-5xl text-[#241d19] text-wrap md:text-balance">
                        <Typewriter
                          options={{
                            strings: [
                              suggestions.animated.first,
                              suggestions.animated.second,
                              suggestions.animated.third,
                              suggestions.animated.fourth,
                            ],
                            autoStart: true,
                            loop: true,
                            deleteSpeed: 5,
                            delay: 50,
                          }}
                        />
                      </p>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <section
              id="home"
              className="home flex flex-col justify-center items-center lg:py-1 w-full"
            >
              <div className="w-full px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12">
                <div className="relative">
                  <input
                    type="text"
                    className="w-full sm:py-3 md:py-4 pl-10 pr-4 text-gray-700 bg-white border rounded-2xl focus:outline-none focus:ring"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <svg
                      className="w-4 h-4 sm:w-5 sm:h-5 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </section>
            <div className="mt-8 sm:mt-10 md:mt-16 lg:mt-9 xl:mt-8">
              <p className="text-center text-sm sm:text-base md:text-lg lg:text-lg xl:text-lg max-w-2xl font-display:swap">
                SearchOwl uses an advanced search algorithm to get the best
                results for you. By adding details to your search such as
                skin type, climate, or acne, your products will become more
                personalized.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </HelmetProvider>
    </motion.div>
  );
};