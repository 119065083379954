import React from 'react';
import '../pages/learn/ArticleCard.css';

function ArticleCard({ image, title, description, author, redirectUrl }) {
  const handleClick = () => {
    window.location.href = redirectUrl;
  };

  return (
    <div className="article-card">
      <div className="article-image-container">
        <img src={image} alt={title} className="article-image" onClick={handleClick} />
      </div>
      <div className="article-content">
        <h4>{title}</h4>
        <p className="article-description">{description}</p>
        <p className="article-author">By {author}</p>
      </div>
    </div>
  );
}

export default ArticleCard;
